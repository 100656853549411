const mobileMenuBtn = document.querySelector(".mobile-menu-icon");
const mobileMenu = document.querySelector(".mobile-menu");
const closeMobileMenu = document.querySelector(".close-mobile-menu-icon");
const navItems = document.querySelectorAll('#nav .menu-item');
if(mobileMenuBtn) {
  mobileMenuBtn.addEventListener('click', function() {
    mobileMenu.classList.add('open');
    document.body.classList.add('fixed-position');
  });
}
if(closeMobileMenu) {
  closeMobileMenu.addEventListener('click', function() {
    mobileMenu.classList.remove('open');
    document.body.classList.remove('fixed-position');
  });
}

if(navItems) {
    navItems.forEach(function(item) {
        item.addEventListener('mouseenter', function() {
          navItems.forEach(otherItem => {
            if (otherItem !== this) {
              otherItem.classList.remove('active');
            }
          });
        });

        item.addEventListener('mouseleave', function() {
          // Restore active class to the original active item
          navItems.forEach(originalItem => {
            if (originalItem.classList.contains('test')) {
              originalItem.classList.add('active');
            }
          });
        });
    });
}

window.addEventListener('scroll', function(e) {
  let scrollpos = window.scrollY
  const header = document.querySelector('.header');
  if(scrollpos > 1) {
    header.classList.add('scrolled');
  } else {
    header.classList.remove('scrolled');
  }
});