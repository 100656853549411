document.addEventListener('click', function (e) {
  const filterOptionsOpen = document.querySelectorAll('.filter-options-container');
  const dropdownSelect = document.querySelectorAll('.filter-field__field.eu-select');
  const dropdownSelectOpen = document.querySelector('.filter-field__field.eu-select.open');
  const mobileFilterBtn = document.querySelector('.mobile-filter-btn');
  filterOptionsOpen.forEach((elem, i) => {
    if(elem.classList.contains('open')) {
      if(!elem.contains(e.target)) {
        elem.classList.remove('open');
      }
    }
  });
  dropdownSelect.forEach((elem, i) => {
    if(elem.contains(e.target)) {
      if(!e.target.classList.contains('open')) {
        e.target.classList.add('open');
        elem.parentElement.nextElementSibling.classList.add('open');
      } else {
        e.target.classList.remove('open');
      }
    }
  });

  if(mobileFilterBtn) {
    if(mobileFilterBtn.contains(e.target)) {
      const euFilters = document.querySelector('.eu-filters');
      euFilters.classList.toggle('open');
      mobileFilterBtn.classList.toggle('open');
    }
  }
  if(dropdownSelectOpen) {
    dropdownSelectOpen.classList.remove('open');
  }
});

window.addEventListener('scroll', function() {
  const filterDropdown = document.querySelectorAll('.filter-field__field.eu-select');
  filterDropdown.forEach((elem, i) => {
    if(window.innerHeight - elem.getBoundingClientRect().top < 300) {
      elem.parentElement.nextElementSibling.classList.add('bottom');
    } else {
      elem.parentElement.nextElementSibling.classList.remove('bottom');
    }
  });
});