import '../css/app.pcss';
import '../js/slider';
import 'lazysizes';
import './fancybox';
import './menu';
import './filter';
import ClipboardJS from 'clipboard';

var clipboard = new ClipboardJS('.copy-btn');
clipboard.on('success', function(e) {
  e.trigger.className += ' has-tooltip';
  e.clearSelection();
  e.trigger.addEventListener('mouseleave', function() {
    e.trigger.className = e.trigger.className.replace(' has-tooltip', '');
    e.trigger.removeEventListener('mouseleave', e.eventListener);
  });
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}