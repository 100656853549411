import Swiper, {EffectFade, Autoplay, Navigation, Pagination} from "swiper";
import {gsap} from "gsap";
Swiper.use([EffectFade, Autoplay, Navigation, Pagination]);
import 'swiper/swiper-bundle.min.css';

const heroSlider = document.querySelectorAll('.hero-slider-container .swiper');
heroSlider.forEach(function (elem, i) {
  new Swiper(elem, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 1000,
    },
    speed: 1000,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init: function () {
        const swiper = this;
        const currentSlide = swiper.slides[swiper.activeIndex];
        const heroTitle = currentSlide.getAttribute('data-title');
        const heroDesc = currentSlide.getAttribute('data-desc');

        const heroContentContainer = document.querySelector('.hero-slider-content');

        heroContentContainer.innerHTML = '<div class="scroll slide-anim"></div><div class="slide-anim banner-title">'+heroTitle+'</div>' +
            '<div class="slide-anim banner-desc">'+heroDesc+'</div>';

        gsap.set('.slide-anim', {autoAlpha: 0, y:-10});
        gsap.to('.slide-anim', {autoAlpha: 1, y: 0, stagger: 0.2})
      },
      slideChangeTransitionStart: function () {
        const swiper = this;
        const currentSlide = swiper.slides[swiper.activeIndex];
        const heroTitle = currentSlide.getAttribute('data-title');
        const heroDesc = currentSlide.getAttribute('data-desc');

        const heroContentContainer = document.querySelector('.hero-slider-content');

        heroContentContainer.innerHTML = '<div class="scroll slide-anim"></div><div class="slide-anim banner-title">'+heroTitle+'</div>' +
            '<div class="slide-anim banner-desc">'+heroDesc+'</div>';

        gsap.set('.slide-anim', {autoAlpha: 0, y:-10});
        gsap.to('.slide-anim', {autoAlpha: 1, y: 0, stagger: 0.2})
      }
    }
  })
})